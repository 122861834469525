"use client";

import { PropsWithChildren, useEffect } from "react";
import {
  LeadFormEventListeners,
  LeadFormPluginOptions,
} from "@/types/LeadFormPlugin";
import type { FormData } from "./types";
import Base64Encode from "@/components/Base64Encode";
import { getCorrelationID, hyphenateForTagular } from "@/utils/tagular/helpers";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";
import { ConversionTracked } from "@/hooks/eventing/types/core.conversions.ConversionTracked.v2";
import makeEvent from "@/hooks/eventing/makeEvent";
import { EVENTS } from "@/hooks/eventing/types/events";
import { sendTaxiFormEvent, TaxiFormSegmentEventData } from "@/lib/segment";

type FormProductData = {
  brand?: string;
  category?: string;
  productvariant?: string;
  name?: string;
};

type CohesionProductData = FormProductData & {
  action: string;
};

export type UseTaxiFormProps = {
  formGrouping: string;
  formId: string;
  postSubmitUrl: string;
  salt?: string;
  taxiFormData?: FormData | null;
  eventListeners?: LeadFormEventListeners;
  isLoading?: boolean;
  formClass?: string;
  segmenteventdata?: TaxiFormSegmentEventData;
  productData: FormProductData;
};

export function injectTaxiForm(onLoadCallback: () => void) {
  if (typeof window === "undefined") {
    return;
  }
  // if we've already loaded the script, don't load it again
  if (window.LeadFormPlugin) {
    onLoadCallback();
    return;
  }

  const script = document.createElement("script");
  script.src = "https://www.edx.org/lead.form.plugin.js";
  script.async = true;

  script.onload = onLoadCallback; // Call the callback function when the script has loaded

  document.body.appendChild(script);
}

const generateCohesionPayload = ({
  action,
  brand,
  category,
  productvariant,
  name,
}: CohesionProductData) =>
  ({
    correlation: {
      id: getCorrelationID(),
    },
    metadata: {
      category: "Lead",
      action,
      timestamp: new Date().toISOString(),
      productList: [
        {
          brand: hyphenateForTagular(brand ?? ""),
          variant: translateVariant(productvariant ?? ""),
          category: category,
          name: name,
        },
      ],
    },
  }) as ConversionTracked;

const trackNextStep = (productData: FormProductData) => {
  // This closure ensures that the tracking event only fires on the first press of the 'Next Step' button.
  let hasRun = false;
  return () => {
    if (!hasRun) {
      hasRun = true;
      const payload = generateCohesionPayload({
        ...productData,
        action: "Started",
      });

      const event = makeEvent<ConversionTracked, EVENTS>(payload);
      event<ConversionTracked>(EVENTS.ConversionTracked)();
    }
  };
};

const trackSubmitted = (
  segmenteventdata?: TaxiFormSegmentEventData,
  productData?: FormProductData,
) => {
  const payload = generateCohesionPayload({
    ...productData,
    action: "Submitted",
  });

  const event = makeEvent<ConversionTracked, EVENTS>(payload);
  event<ConversionTracked>(EVENTS.ConversionTracked)();

  if (segmenteventdata) {
    sendTaxiFormEvent(segmenteventdata);
  }
};

export default function useTaxiForm({
  formGrouping,
  formId,
  postSubmitUrl,
  salt = "___",
  taxiFormData,
  eventListeners,
  isLoading,
  formClass,
  segmenteventdata,
  productData,
}: UseTaxiFormProps) {
  const resolvedFormId = formId;
  const packageFromId = `${salt}-${formGrouping}-${resolvedFormId}-full-taxi-lead-form-package`;
  const templateFromId = `${salt}-lead-form-template`;
  const mountPointId = `${salt}-taxi-plugin-mount-point`;
  const titleId = `${salt}-title`;
  const introTextId = `${salt}-intro_text`;
  const progressBarId = `${salt}-lead-form-progress-bar-mount-point`;
  const errorMountPointId = `${salt}-lead-form-error-mount-point`;
  const fieldMountPointId = `${salt}-lead-form-field-mount-point`;
  const nextActionButtonId = `${salt}-lead-form-next-action-button`;
  const footerMountPointId = `${salt}-lead-form-footer-mount-point`;

  useEffect(() => {
    if (isLoading) return;

    injectTaxiForm(() => {
      if (!taxiFormData) return;
      const options: LeadFormPluginOptions = {
        salt: salt,
        thankYouURL: postSubmitUrl,
        packageType: "element",
        packageFrom: packageFromId,
        templateType: "element",
        templateFrom: templateFromId,
        telemetryEnabled: false,
        hostPresetFields: {
          ip: "", // window.visitor?.ip?.address || "0.0.0.0",
          lead_source: "edx",
          // page_url: pageUrlWithEAID,
          eaid: "",
          rv_source: "organic",
          // splash_creative: '', // TODO: figure out how to supply this
        },
      };

      const defaultEventListeners = {
        nextScreenTransitionSucceeded: trackNextStep(productData),
        imqSubmissionAttempt: trackSubmitted(segmenteventdata, productData),
      };

      if (eventListeners) {
        options.engineEventListeners = {
          ...defaultEventListeners,
          ...eventListeners,
        } as LeadFormEventListeners;
      }

      const taxiForm = new window.LeadFormPlugin(
        window.document,
        mountPointId,
        options,
      );
      taxiForm.start();
    });
  }, [
    mountPointId,
    packageFromId,
    postSubmitUrl,
    salt,
    templateFromId,
    taxiFormData,
    isLoading,
  ]);

  const TaxiForm = ({ children }: PropsWithChildren) => {
    if (!taxiFormData || isLoading) return null;
    return (
      <section className="taxi-form-section">
        <div className={`taxi-form ${formClass || ""}`}>
          <pre id={packageFromId} className="hidden">
            {JSON.stringify(taxiFormData)}
          </pre>
          <div
            id={mountPointId}
            data-package-type="element"
            data-template-type="element"
          />
        </div>
        <div
          id={templateFromId}
          className="hidden"
          aria-hidden="true"
          data-encoded="base64"
        >
          <Base64Encode>{children}</Base64Encode>
        </div>
      </section>
    );
  };

  return {
    TaxiForm,
    templateIds: {
      titleId,
      introTextId,
      progressBarId,
      errorMountPointId,
      fieldMountPointId,
      nextActionButtonId,
      footerMountPointId,
    },
  };
}
